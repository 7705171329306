import { Report } from "./reports.model";

export class LoadReports {
    public static readonly type = "[Reports] load reports";
    constructor(
        public filters?: any
    ) {}
}

export class AddReport {
    public static readonly type = "[Reports] add report";
    constructor(
        public payload: Report,
        public callbackSuccess: () => void,
        public callbackError: (error) => void
    ) {}
}

export class UpdateReport {
    public static readonly type = "[Reports] update report";
    constructor(
        public payload: Report,
        public callbackSuccess: () => void,
        public callbackError: () => void
    ) {}
}

export class RemoveReport {
    public static readonly type = "[Reports] remove report";
    constructor(
        public reportId: string,
        public callbackSuccess: () => void,
        public callbackError: () => void
    ) {}
  }
  